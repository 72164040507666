import Blockquote from "components/Blockquote";
import * as React from "react";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";

export default function Page() {
  return (
    <BlogLayout slug="a-turning-point">
      <Paragraph>
        2023 has ushered in <strong>a big season of change</strong> at EOCP:
        We’ve welcomed interim executive leadership and hired key staff at
        Crossroads, our emergency shelter located in the heart of East Oakland.{" "}
        <strong>
          Our commitment to persistent, comprehensive care for families
          experiencing homelessness continues.
        </strong>{" "}
        And this year, we are finding new opportunities to strengthen how we
        show up for parents and youth at Crossroads and beyond.
        <br />
        <br />
        Every family seeking the next step in their journey towards permanent
        housing finds much more than simply a shelter bed at EOCP — they are
        welcomed by our team’s{" "}
        <strong>commitment to intensive support. </strong>We first learn about
        the personal and complex story of each family’s experiences to date,
        deeply listening to understand their goals and needs. With on-site
        counseling for mental health and substance use, family members receive
        personalized attention.
      </Paragraph>

      <Blockquote>
        In 2022….
        <br />
        74% of families served exited the program
        <br />
        38% to permanent housing
        <br />
        40% increased their income
      </Blockquote>

      <Paragraph>
        <strong>There are unique challenges for unhoused families.</strong>{" "}
        Carmen Hidalgo (Crossroads’ Family Services Manager, who joined EOCP
        last summer), reflected: “One of the major challenges we see is about
        education. When families arrive at Crossroads, we want to keep the
        children in each family connected by getting them enrolled in school,
        especially when they may not have been enrolled in school for a long
        time. For our team, part of our work is supporting parents to build the
        daily habit of taking their kids to school.” For others, working with
        EOCP case managers offers an opportunity to try to reunite with a child
        from whom they’ve been separated. And for both parents and children, it
        is difficult to tackle aspects of depression and other mental health
        crises that affect not only individuals, but an entire family. EOCP
        staff bring an understanding of these hurdles to each family’s
        personalized plan of action.
        <br />
        <br />
        While Crossroads offers a crucial landing place and space of comfort,{" "}
        <strong>
          making a way out of the shelter and into permanent housing is the goal
        </strong>
        . For the families at Crossroads, there are already wins: 100% of them
        are in the midst of their housing placement process after securing
        either their emergency housing Section 8 voucher or a match through our
        Permanent Supportive Housing (PSH) program. At each stage, our case
        managers work closely with each family to gather and submit required
        documents, and ensure they access important social benefits.
      </Paragraph>
      <Blockquote>
        Making a way out of the shelter and into permanent housing is the goal.
      </Blockquote>
      <Paragraph>
        Beyond the families on-site at Crossroads, Carmen and her team also
        provide tailored care and housing placement services for an additional
        30 unhoused families per year as part of the CalWORKs Housing Support
        Program (HSP). Two additional programs support families who are turning
        the page on their story with homelessness: the Matilda Cleveland
        Transitional Housing and the Family Matters Shelter. Here dozens more
        caregivers and children work with dedicated EOCP case managers to
        rapidly find permanent housing and access to a robust slate of services.
        <br />
        <br />
        <strong>
          At all our family programming sites, this season of change is bringing
          a renewed energy for the possibilities to come!
        </strong>
        . As EOCP and our committed team move out of the height of the COVID
        pandemic — and all its difficulties, losses, and uncertainties — we are
        continuing to open new doors for people of all ages with our commitment
        to dignified, compassionate, unwavering care.
      </Paragraph>
    </BlogLayout>
  );
}
